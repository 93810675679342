declare global {
    interface Window {
        eventSource: EventSource
    }
}

type IPossibleEvents = CustomEvent | Event;

export type IEvent<T extends IPossibleEvents> = {
    [P in keyof T]?: T[P];
} & { container: HTMLElement };

export default class fEvents {
    private static _listeners;
    private static _eventSource: { [key: string]: EventSource } = {};
    /**
     * Dispatches an event
     * @param {Object} webcomponent - Component that spawns the event
     * @param {string} name - Event name
     * @param {Object} detail - Give this data in the event.
     */
    static dispatch = (webcomponent: HTMLElement, name: string, detail: Record<string, any> ): void => {
        const publicEvent = new CustomEvent(name, {
            detail,
            bubbles: true,
            composed: true,
        });
        webcomponent.dispatchEvent(publicEvent);
    };

    static attachEvent = (target: string, on: string, handler: (a: IEvent<IPossibleEvents>) => any, root: ShadowRoot | Document = document, useCapture = false ): void =>
        (root || document).addEventListener(on, (evt: IPossibleEvents) => {
            const container = (evt.target as HTMLElement).closest(
                target
            ) as HTMLElement;
            if (container) {
                const event = Object.assign(evt, { container });
                handler(event);
            }
            return false;
        }, useCapture);

    static detachEvent = (target: string, on: string, handler: (a: IEvent<IPossibleEvents>) => any, root: ShadowRoot | Document = document, useCapture = false ): void =>
        (root || document).removeEventListener(on, (evt: IPossibleEvents) => {
            const container = (evt.target as HTMLElement).closest(
                target
            ) as HTMLElement;
            if (container) {
                const event = Object.assign(evt, { container });
                handler(event);
            }
            return false;
        }, useCapture);

    /**
     * Subscribes to real-time server events for a given member.
     * This function listens for messages on a server-sent event source
     *
     * @param {string} memberId - The unique identifier for the member.
     * @param {function} onMessage - The callback function to handle incoming messages.
     */
    static listenMemberRealtimeServer = (memberId: string, onMessage): void => {
        fEvents._listeners = fEvents._listeners || {};
        if (fEvents._listeners[onMessage]) {
            /* Handling Duplicate Callbacks:
            *    - This allows multiple components on a single page(window)
            *      to use the same callback function without duplicating listener registration.
            */
            return window.eventSource.addEventListener('message', onMessage);
        }

        if (!window.eventSource) {
            window.eventSource = window.opener
                ? window.opener.eventSource
                : new EventSource('/v2/chat-events/' + memberId);
        }
        fEvents._listeners[onMessage] = e => onMessage(e);
        window.eventSource.addEventListener(
            'message',
            fEvents._listeners[onMessage]
        );
    };

    /**
     * @description Listen to a realtime connection
     * - this is used for the browser stream
     * - this can be used multiple times, just make sure to stop listening to it
     * we keep track of the event source so we can stop listening to it later
     * @param eventId - csrfToken that is connected to the login session
     * @param onMessage - callback function that is called when a message is received
     */
    static listenRealtimeServer = (eventId: string, onMessage): void => {
        fEvents._eventSource = fEvents._eventSource || {};

        if (fEvents._eventSource[eventId]) {
            return console.warn('Listener already connected');
        }

        // save this event source so I can stop listening to it later
        fEvents._eventSource[eventId] = new EventSource('/v2/realtime-events/' + eventId);

        fEvents._eventSource[eventId].addEventListener(
            'message',
            onMessage
        );
    };

    static stopListeningRealtime = (eventId, onMessage) => {
        const eventSource = fEvents._eventSource[eventId];
        if (eventSource) {
            eventSource.close();
            delete fEvents._eventSource[eventId];
            eventSource.removeEventListener('message', onMessage);
        }
    };

    /**
     * @description Stop listening to the chat server
     * @param onMessage - callback function that is called when a message is received
     */
    static stopListeningMemberRealtimeServer = onMessage => {
        const eventSource = window.eventSource;
        if (fEvents._listeners[onMessage]) {
            eventSource.removeEventListener('message', fEvents._listeners[onMessage]);
            delete fEvents._listeners[onMessage];
        }

        // Incase there are multiple callbacks with the same name
        // we remove the one from the state object and any other callbacks
        eventSource.removeEventListener(
            'message',
            onMessage
        );
    };
}

(() => { // Make url changes fire an event
    const oldPushState = history.pushState;
    history.pushState = function pushState(...args) {
        const ret = oldPushState.apply(this, args);
        window.dispatchEvent(new Event('pushstate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    };

    const oldReplaceState = history.replaceState;
    history.replaceState = function replaceState(...args) {
        const ret = oldReplaceState.apply(this, args);
        window.dispatchEvent(new Event('replacestate'));
        window.dispatchEvent(new Event('locationchange'));
        return ret;
    };

    window.addEventListener('popstate', () => {
        window.dispatchEvent(new Event('locationchange'));
    });
})();
